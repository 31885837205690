import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { AuthGuard } from "../App";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const ReservationsPage = () => {
  const [reservations, setReservations] = useState([]);
  const [webUrl, setWebUrl] = useState(""); // State for payment URL
  const [showIframe, setShowIframe] = useState(false); // State for iframe visibility
  const navigate = useNavigate();

  useEffect(() => {
    const propertyId = localStorage.getItem("selectedPropertyId");
    const token = localStorage.getItem("token");

    if (propertyId && token) {
      // Fetch reservations
      axios
        .get(
          `https://backend.inyumba.co.ke/iNyumba/user/reservations/viewAll/${propertyId}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((response) => {
          setReservations(response.data.response);
        })
        .catch((error) => {
          console.error("Error fetching reservations:", error);
        });
    }
  }, []);

  const handleViewBills = (roomId) => {
    // Set rental ID (room_id) in local storage
    localStorage.setItem("selectedRentalId", roomId);
    // Redirect to the bills page for the specific rental
    navigate("/caretaker/bills");
  };

  const handleBacktoHome = (roomId) => {
    navigate("/caretaker/home");
  };

  const handlePayment = async (userId) => {
    const token = localStorage.getItem("token");

    try {
      const response = await axios.post(
        `https://backend.inyumba.co.ke/iNyumba/user/payment/initiate-payment/${userId}`,
        null,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setWebUrl(response.data.response); // Set the payment URL from the response
      setShowIframe(true); // Show the iframe
    } catch (error) {
      console.error("Error initiating payment:", error);
    }
  };

  const handleMpesaPayment = (userId) => {
    const token = localStorage.getItem("token");

    axios
      .post(
        `https://backend.inyumba.co.ke/iNyumba/user/mpesapayment/rentpaymentinitiatestk/${userId}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        toast.success(response.data.response);
        setShowIframe(false); // Show the iframe
      })
      .catch((error) => {
        console.error("Error initiating mpesa payment:", error);
        toast.error(error.response.data.response);
      });
  };

  const handleCloseIframe = () => {
    setShowIframe(false); // Hide the iframe
    setWebUrl(""); // Clear the payment URL
  };

  return (
    <AuthGuard>
      <div className="container mx-auto px-4 py-8">
        <h1 className="text-3xl font-semibold mb-4">Reservations</h1>

        <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
          {reservations.map((reservation) => (
            <div
              key={reservation.room_id}
              className="bg-white shadow-md rounded-md p-4"
            >
              <p>
                <strong>Name:</strong> {reservation.user_name}
              </p>
              <p>
                <strong>Email:</strong> {reservation.email}
              </p>
              <p>
                <strong>Phone:</strong> {reservation.phone}
              </p>
              <p>
                <strong>House Number:</strong> {reservation.house_no}
              </p>
              <p>
                <strong>Payment Status:</strong> {reservation.payment_status}
              </p>
              {/* Button for viewing bills */}
              <button
                onClick={() => handleViewBills(reservation.room_id)}
                className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mt-2"
              >
                Set Tenant Bills
              </button>
              {/* Conditionally render Pay button */}
              {reservation.payment_status === "PENDING" && (
                <div>
                  <button
                    onClick={() => handlePayment(reservation.user_id)}
                    className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded mt-2"
                    disabled
                  >
                    Trigger Tenant Payment iPay
                  </button>
                  <button
                    onClick={() => handleMpesaPayment(reservation.user_id)}
                    className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded mt-2 ml-2"
                  >
                    Trigger Tenant Payment Mpesa
                  </button>
                </div>
              )}
            </div>
          ))}
        </div>

        {/* Add button to navigate back */}
        <button
          onClick={() => handleBacktoHome()}
          className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mt-8"
        >
          Go Back
        </button>

        {/* Conditionally render the iframe with close button */}
        {showIframe && webUrl && (
          <div className="fixed inset-0 bg-gray-800 bg-opacity-75 flex flex-col items-center justify-center">
            <button
              onClick={handleCloseIframe}
              className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded mb-4"
            >
              Close
            </button>
            <iframe
              src={webUrl}
              className="w-screen h-screen border-none mx-auto"
              title="Payment Iframe"
            ></iframe>
          </div>
        )}
      </div>
    </AuthGuard>
  );
};

export default ReservationsPage;
